import React, { useEffect, useState } from "react"

import Cookies from 'js-cookie'

export default ({ 
    details,
}) => {
    const {
      title,
      subtitle,
      copy,
      link,
      image,
    } = details

    const [isVisible, setIsVisible] = useState( false )

    const _window = typeof window !== 'undefined' ? window : null

    useEffect(() => {
        if(_window){
            setTimeout(() => {
                const hasDismissed = Cookies.get('hasDismissed')

                if(!hasDismissed){
                    setIsVisible( true )
                }
            },1000)
        }
    }, [_window])

    const handleDismiss = () => {
        setIsVisible( false )

        Cookies.set('hasDismissed', 'true', { expires: 7 })
        
    }

    const followLink = () => {
        handleDismiss()

        setTimeout(() => {
            window.location.href = link.url
        },200)

    }

    return (
        <div className={`websitePopup ${ isVisible ? 'visible' : '' }`}>
            <div className="websitePopup-cont" style={{ backgroundImage: `url(${ image && image.mediaItemUrl })` }}>
                <h1>
                    <em dangerouslySetInnerHTML={{ __html: title }} />
                    { subtitle }
                </h1>
                <div className="websitePopup-copy" dangerouslySetInnerHTML={{ __html: copy }} />
               { link && <div onClick={ followLink } dangerouslySetInnerHTML={{ __html: link.title }} className="button border" /> }
               <div className="websitePopup-close" onClick={ handleDismiss } />
            </div>
        </div>
    )
}
