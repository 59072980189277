import React, { useContext } from "react"
import { MainContext } from "context/context"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FlexibleContent from "../components/flexibleContent"
import WebsitePopup from "../components/websitePopup"
import SubscribePopup from "../components/subscribePopup"

export default ({ location, data }) => {
  const {
    databaseId,
    title,
    excerpt,
    flexibleContent,
    seoFields
  } = data.siteFields.page
  
  const {
      options
  } = useContext(MainContext)

  return (
    <Layout isFrontPage="true" location={ location }>
      <SEO 
        title={title} 
        description={excerpt} 
        seoFields={seoFields}
      />
      { ( options.websitePopup.title && !options.websitePopup.hide ) ?
        <WebsitePopup details={ options.websitePopup } />
      :
        options.websitePopup.showSubscribePopup &&
          <SubscribePopup />
      }
      <FlexibleContent id={databaseId} content={flexibleContent} />
    </Layout>
  )
}

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                date
                content(format: RENDERED)
                seoFields {
                  metaTitle
                  metaDescription
                  ogpImage {
                    mediaItemUrl
                  }
                }
                featuredImage {
                  node {
                    altText
                    title(format: RENDERED)
                    mediaItemUrl
                    slug
                  }
                }
                flexibleContent {
                    ... FlexibleContentFragment
                }
            }
        }
    }
`
