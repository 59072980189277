import React, { useEffect, useState } from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Cookies from 'js-cookie'

export default () => {

    const [isVisible, setIsVisible] = useState( false )

    const _window = typeof window !== 'undefined' ? window : null

    useEffect(() => {
        if(_window){
            setTimeout(() => {
                const hasDismissedSubscribe = Cookies.get('hasDismissedSubscribe')

                if(!hasDismissedSubscribe){
                    setIsVisible( true )
                }
            },1000)
        }
    }, [_window])

    const handleDismiss = () => {
        setIsVisible( false )

        Cookies.set('hasDismissedSubscribe', 'true', { expires: 7 })
        
    }

    const [emailValue, setEmailValue] = useState('')

    const [email, setEmail] = useState('')

    const [formMsg, setFormMessage] = useState('')

    const handleSubmit = e => {
        e.preventDefault()

        addToMailchimp(email).then(data => {
            setFormMessage( '<p>' + data.msg + '<p>')

            if (data.result === 'success') {
                setEmail('')
                setEmailValue('')

                setTimeout(() => {
                    handleDismiss()
                },1000)
            }
        }).catch(() => {})
    }

    const handleEmailChange = (event) => {
        
        setEmail(event.currentTarget.value)
        setEmailValue(event.currentTarget.value)

    }

    return (
        <div className={`websitePopup subscribe ${ isVisible ? 'visible' : '' }`}>
            <div className="websitePopup-cont">
                <h1>
                    <em dangerouslySetInnerHTML={{ __html: 'Join us' }} />
                    at Pentridge.
                </h1>
                <div className="websitePopup-copy" dangerouslySetInnerHTML={{ __html: 'Sign up to receive news, stories and special event invites.' }} />
                <form onSubmit={ handleSubmit }>
                    <input type="email" placeholder="Enter your email address" value={ emailValue } onChange={ handleEmailChange } />
                    <button type="submit" className="button border">Subscribe</button>
                </form>
                <div className="websitePopup-formMsg" dangerouslySetInnerHTML={{ __html: formMsg }} />
                <div className="websitePopup-close" onClick={ handleDismiss } />
            </div>
        </div>
    )
}
